<script setup>
import { useTabs } from "./useTabs";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const tabsGroup = reactive(useTabs());

provide("tabsGroup", tabsGroup);

watchEffect(() => setValue(props.modelValue));
watch(
  () => tabsGroup.tabsColl,
  () => updateValue(),
  { deep: true }
);

function setValue(value) {
  setTimeout(() => {
    tabsGroup.activate(value);
  });
}

function updateValue() {
  const tabEl = tabsGroup.tabsColl.find((t) => t.isTabActive);
  if (tabEl) setValue(tabEl.tabValue);
}
</script>
<template>
  <div class="v-tabs-el">
    <slot></slot>
  </div>
</template>
<style scoped></style>
